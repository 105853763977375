<template>
  <action-dispatcher
    action="blog/get"
    :parameters="$route.params.id"
    class="view-blog-post container py-5"
  >
    <div
      class="pb-4 text-secondary mobile-small d-flex d-lg-block align-items-center"
    >
      <router-link
        class="text-light text-decoration-none"
        :to="{ name: 'blog' }"
      >
        Reflexões
      </router-link>
      <i class="fa-regular fa-chevron-right mx-2"></i>
      <router-link
        class="text-light text-decoration-none"
        :to="{ name: 'blog', query: { category: post.category.id } }"
      >
        {{ post.category.name }}
      </router-link>
      <i class="fa-regular fa-chevron-right mx-2"></i>
      {{ post.title }}
    </div>

    <div class="position-relative">
      <div class="ratio ratio-21x9 mb-4">
        <img :src="post.cover" class="img-fluid" />
      </div>
      <span
        class="badge bg-primary text-dark position-absolute bottom-0 end-0 m-2"
        >{{ post.category.name }}</span
      >
    </div>
    <h1 class="mb-0 font-serif">{{ post.title }}</h1>
    <div class="text-secondary small mb-4">
      {{ post.author.name }}, {{ date }}
    </div>
    <div v-html="post.text" class=""></div>
  </action-dispatcher>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("blog", ["post"]),
    date() {
      return new Date(this.post?.published_at)?.toLocaleDateString("pt-BR", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.view-blog-post {
  max-width: 900px;
}
</style>
